<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-title v-if="title">
        <span class="text-h3">{{ $t(title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.name"
                dense
                :label="$t('name_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              v-if="authUser.isAdmin"
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.product"
                dense
                :items="['oem', 'fleet']"
                item-text="name"
                item-value="id"
                :disabled="type == 'edit'"
                :label="$t('select_product_*')"
                :rules="[rules.required]"
                @change="productChange"
              />
            </v-col>
            <v-col
              v-if="authUser.isAdmin"
              cols="6"
              sm="6"
            >
              <v-autocomplete
                v-model="options.tenant"
                dense
                :loading="tenantLoading"
                :items="tenantsByProduct"
                item-text="name"
                item-value="id"
                :label="$t('select_tenant_*')"
                :rules="[rules.required]"
                @change="onTenantChange"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.terminal"
                dense
                :items="options.tenant ? terminalList : []"
                item-value="id"
                item-text="name"
                :label="$t('terminal_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.type"
                dense
                :items="typeOptions"
                item-value="id"
                item-text="name"
                :label="$t('type') + ' *'"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.capacity"
                dense
                :label="$t('capacity') + ' (L) *'"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.fuel_type"
                dense
                :items="fuelTypeOptions"
                item-value="id"
                item-text="name"
                :label="$t('fuel_type') + ' *'"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.operational_status"
                dense
                :items="statusOptions"
                item-value="id"
                item-text="name"
                :label="$t('status_*')"
                :rules="[rules.required_options]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.min_temperature"
                :rules="[rules.latlng]"
                dense
                :label="$t('min_temp')"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.max_temperature"
                dense
                :rules="[rules.latlng]"
                :label="$t('max_temp')"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-if="!type == 'create' && options.iot_device"
                dense
                :value="options.iot_device_imei.imei"
                :label="$t('iot_device_*')"
                disabled
              />
              <v-select
                v-model="options.iot_device"
                dense
                :items="
                  authUser.isAdmin
                    ? options.tenant
                      ? iotDevicesList
                      : []
                    : iotDevicesList
                "
                :loading="isLoadingIotDevices"
                item-value="id"
                item-text="imei"
                clearable
                :label="$t('iot_device') + ' *'"
              />
              <!-- :rules="[rules.required]" -->
              <!-- :rules="[rules.required]" -->
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small>{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  mixins: [validationRules],
  props: {
    type: {
      type: String,
      default() {
        return {
          time: null,
        };
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      options: {},
      product: null,
      tenantOEM: [],
      statusOptions: [
        {
          id: 1,
          name: "active",
        },
        {
          id: 0,
          name: "inactive",
        },
      ],
      typeOptions: [
        {
          id: "primary",
          name: "Primary",
        },
        {
          id: "secondary",
          name: "Secondary",
        },
      ],
      fuelTypeOptions: [
        {
          id: "petrol",
          name: "Petrol",
        },
        {
          id: "diesel",
          name: "Diesel",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoadingIotDevices: "iotDevices/getIsLoadingIotDevices",
      iotDevicesList: "iotDevices/getIotDevicesList",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantOptions",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsFLEET: "tenantsManagement/getTenantsFLEET",
      terminalList: "fuelTanks/getTerminalDropDown",
      terminalLoading: "fuelTanks/getDropdownloadingTerminal",
    }),
    tenantsByProduct() {
      if (this.options?.product) {
        if (this.options.product == "oem") {
          return this.tenantsOEM;
        } else {
          return this.tenantsFLEET;
        }
      } else {
        return [];
      }
    },
    title() {
      if (this.type == "create") {
        return "add_new";
      } else {
        return "";
      }
    },
  },
  watch: {},
  mounted() {
    if (this.type == "create") {
      if (!this.authUser.isAdmin) {
        this.options.tenant = this.authUser.tenant_id;
        this.onTenantChange(this.authUser.tenant_id);
      }
    }
    if (this.type == "edit") {
      this.options = { ...this.item };
      this.onTenantChange(this.options.tenant);
    }
  },
  methods: {
    productChange() {
      delete this.options.tenant;
    },
    onTenantChange(v) {
      this.$store.dispatch("fuelTanks/dropDownListTerminal", { tenant_id: v });
      let params = {
        tenant_id: v ?? false,
        vehicle_assigned: false,
      };
      if (this.type == "edit") {
        if (this.options.iot_device) {
          params = {
            tenant_id: v ?? false,
            vehicle_assigned_to: this.options.iot_device,
          };
        }
      }
      this.$store.dispatch("iotDevices/list", params);
    },
    saveItem() {
      this.options.status = this.options.operational_status;
      if (this.$refs.form.validate()) {
        this.$emit("onsave", this.options);
      }
    },
    closeForm() {
      this.$refs.form.reset();
      this.$emit("onclose");
    },
  },
};
</script>
